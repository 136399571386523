.badge-bigger {
  font-size: 85%;
}

a {
  color: #28a745;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #28a745;
  border-color: #28a745;
  color: rgb(255, 255, 255);
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: rgb(255, 255, 255);
}

.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option--highlighted[aria-selected]:hover {
  background-color: #28a745;
  color: rgb(255, 255, 255);
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}

.page-link {
  color:#28a745;
}

.page-link:hover {
  z-index: 2;
  color: #28a745;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgb(255 166 0 / 25%);
}

.btn-outline-plain {
  color: #6c757d;
  border-color: #e1e1e1;
}

.container-fluid {
  color: dimgrey;
}

body {
    font-size: 0.9rem;
}
